<template>
  <el-main>
    <page-title show-back-btn />
    <div v-loading="loading.detail" class="partition-area">
      <el-form label-width="180px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="授信进度">
          <el-col :span="24">
            <el-steps :active="stepActiveIndex" finish-status="success">
              <el-step title="发起申请" />
              <el-step title="补件" />
              <el-step title="审批" />
              <el-step title="完成" />
            </el-steps>
          </el-col>
        </ics-page-inner>
        <div class="tabs-inner">
          <el-tabs v-model="activeName" type="card" @tab-click="tabClickRoleList()">
            <el-tab-pane label="授信信息" name="credit">
              <ics-page-inner v-if="stepActiveIndex < 3" title="授信信息">
                <el-col :span="12">
                  <el-form-item label="授信编号">
                    <p>{{ utils.isEffectiveCommon(creditDetail.creditCode) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="融资企业">
                    <p>{{ utils.isEffectiveCommon(creditDetail.customerName) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="核心企业">
                    <p>{{ utils.isEffectiveCommon(creditDetail.coreName) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="资方">
                    <p>{{ utils.isEffectiveCommon(creditDetail.capName) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="申请额度(元)">
                    <p>{{ utils.moneyFormat(creditDetail.applyAmount, 2) }}</p>
                  </el-form-item>
                </el-col>
              </ics-page-inner>
              <ics-page-inner v-if="stepActiveIndex > 3" title="授信信息">
                <el-col :span="12">
                  <el-form-item label="授信编号">
                    <p>{{ utils.isEffectiveCommon(creditDetail.creditCode) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="融资企业">
                    <p>{{ utils.isEffectiveCommon(creditDetail.customerName) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="核心企业">
                    <p>{{ utils.isEffectiveCommon(creditDetail.coreName) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="资方">
                    <p>{{ utils.isEffectiveCommon(creditDetail.capName) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="总授信额度(元)">
                    <p>{{ utils.moneyFormat(creditDetail.passAmount, 2) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="授信期限">
                    <p>{{ creditDetail.effectiveStart===undefined ? '' : (creditDetail.effectiveStart + ' 至 ' + creditDetail.effectiveEnd) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="可用授信额度(元)">
                    <p>{{ utils.moneyFormat(creditDetail.useSurplusAmount, 2) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="已用信额度(元)">
                    <p>{{ utils.moneyFormat(creditDetail.useAmount, 2) }}</p>
                  </el-form-item>
                </el-col>
              </ics-page-inner>
              <ics-product-info-inner :stage="productKey" :product-info="productInfo" />
              <ics-page-inner title="资产信息-基础合同">
                <el-col :span="24">
                  <div class="partition-area">
                    <el-table :data="contractBaseInfo" class="table-input" max-height="250">
                      <el-table-column prop="contracNo" label="基础合同编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="100" />
                      <el-table-column prop="contracName" label="基础合同名称" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                      <el-table-column prop="contracAmount" label="基础合同金额" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
                      <el-table-column prop="signTime" label="签订时间" align="center" :formatter="(row, col, cell) => utils.dateFormat(cell)" show-overflow-tooltip min-width="150" />
                      <el-table-column prop="dueTime" label="合同到期时间" align="center" :formatter="(row, col, cell) => utils.dateFormat(cell)" show-overflow-tooltip min-width="150" />
                      <el-table-column label="操作" align="center" fixed="right" show-overflow-tooltip min-width="150">
                        <template v-slot="scope">
                          <el-link type="danger" class="text-btn" @click="clickDialog(scope.row)">
                            查看发票
                          </el-link>
                          <el-link type="danger" class="text-btn" @click="utils.downloadP('other', scope.row.filesList[0].url)">
                            下载
                          </el-link>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-col>
              </ics-page-inner>
              <ics-bank-files-inner :bank-files-info="bankFilesInfo" :bank-key="bankKey" :method="method" :flow-state-code="creditDetail.flowStateCode" />
            </el-tab-pane>
            <el-tab-pane label="操作记录" name="operating">
              <div class="partition-table">
                <el-table :data="operatingList" border style="width: 100%" class="table-input" max-height="350">
                  <el-table-column prop="operationTypeName" label="操作类型" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="100" />
                  <el-table-column prop="content" label="操作内容" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="100" />
                  <el-table-column prop="operationAnnotation" label="备注" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                  <el-table-column prop="operationName" label="操作人" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                  <el-table-column prop="recordTime" label="操作时间" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                </el-table>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-form>
    </div>
    <ics-dialog-inner width="60%" :visible.sync="dialog.invoice.visible" :title="dialog.invoice.title" cancel-title="返回" class="form-dialog" :show-submit="false">
      <div class="partition-table">
        <el-table :data="invoicesList" border style="width: 100%" class="table-input" max-height="250">
          <el-table-column prop="invoiceCode" label="发票编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="100" />
          <el-table-column prop="invoiceKind" label="发票类型" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'invoiceKindType')" show-overflow-tooltip min-width="100" />
          <el-table-column prop="buyerTaxName" label="采购方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="sellerTaxName" label="销售方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="billingDate" label="开票时间" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="totalAmountTax" label="金额(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
          <el-table-column prop="totalTax" label="税额(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
          <el-table-column prop="invoiceStatus" label="状态" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'invoiceStatus')" show-overflow-tooltip min-width="150" />
          <el-table-column label="操作" fixed="right" align="center" show-overflow-tooltip min-width="150">
            <template slot-scope="scope">
              <a href="javascript:" class="text-btn" @click="utils.downloadP('invoice', scope.row.invoiceUrl)">下载</a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </ics-dialog-inner>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsProductInfoInner from './components/product-info-inner'
import IcsBankFilesInner from './components/bank-files-inner'
import IcsDialogInner from '@/components/dialog-inner'
export default {
  components: { IcsProductInfoInner, IcsBankFilesInner, IcsDialogInner },
  mixins: [routeEnterMixin],
  data() {
    return {
      creditCode: this.$route.query.creditCode,
      stepActiveIndex: 0,
      activeName: 'credit',
      bankKey: 0,
      productKey: 0,
      creditDetail: {},
      contractBaseInfo: [],
      method: '',
      productInfo: {},
      bankFilesInfo: {},
      invoicesList: [],
      operatingList: [],
      dialog: {
        invoice: {
          visible: false,
          title: '查看发票信息'
        }
      }
    }
  },
  created() {
    if (this.creditCode) {
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.loading.detail = false
      this.api.credit.creditDetails({ creditCode: this.creditCode }).then(result => {
        const data = result.data.data
        this.creditDetail = data.credit
        this.contractBaseInfo = data.contractBaseInfo
        this.bankKey = data.snapshot.bankKey
        if (data.snapshot.productKey === 1) {
          this.productKey = 1
        }
        if (data.snapshot.productJson) {
          const productJson = JSON.parse(data.snapshot.productJson) || {}
          this.productInfo = productJson
          if (Number(productJson.repaymentMethod) === 0) {
            this.method = 0
          } else if (Number(productJson.repaymentMethod) === 1) {
            this.method = 1
          } else {
            this.method = ''
          }
        }
        if (data.snapshot.bankJson) {
          this.bankFilesInfo = JSON.parse(data.snapshot.bankJson)
        }
        this.bankFilesInfo.files = data.files
        switch (data.credit.flowStateCode) {
          case 'Wait':
            this.stepActiveIndex = 0
            break
          case 'Supplement_tenant':
            this.stepActiveIndex = 1
            break
          case 'Supplement_cap':
            this.stepActiveIndex = 1
            break
          case 'Cap_approve':
            this.stepActiveIndex = 2
            break
          case 'Fail':
            this.stepActiveIndex = 4
            break
          case 'Completed':
            this.stepActiveIndex = 4
            break
          default:
            this.stepActiveIndex = 0
        }
      }).finally(() => {
        this.loading.detail = false
      })
    },
    tabClickRoleList () {
      if (this.activeName === 'operating') {
        this.getOperatingList()
      }
    },
    getOperatingList () {
      const data = {
        businessId: this.creditCode,
        pageNum: 1,
        pageSize: 99999
      }
      this.api.operating.listPage(data).then(result => {
        this.operatingList = result.data.data.list
      }).finally(() => {
      })
    },
    clickDialog (row) {
      this.invoicesList = row.invoicesList || []
      this.dialog.invoice.visible = true
    }
  }
}
</script>

<style scoped>

</style>
